import { Component, OnInit, OnDestroy, ElementRef, EventEmitter, HostBinding, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, ValidatorFn } from '@angular/forms';
import { TabDirective, TabsetComponent } from 'ngx-bootstrap/tabs';

import { SettingsService } from '../../core/settings/settings.service';
import { DialogService } from '../../chatstyle/dialog/dialog.service';
import { DialogGraph, DialogNode, DialogLink, NameValueItem } from '../../chatstyle/models/dialogs.model'

import { Observable, of } from "rxjs";

@Component({
    selector: 'app-nodesidebar',
    templateUrl: './nodesidebar.component.html',
    styleUrls: ['./nodesidebar.component.scss']
})
export class NodeSidebarComponent implements OnInit, OnDestroy {

  @ViewChild('codeeditor', { static: true }) codeeditor: any;
  @ViewChild('codeTabs', { static: false }) codeTabs: TabsetComponent;

  private editor = null;
  editorOptions = { theme: 'vs-dark', language: 'javascript' };
  code: string = 'function x() {\nconsole.log("Hello world!");\n}';

  public nodeForm: FormGroup;
  public canEdit: boolean;

  editorOpts = {
    mode: 'javascript',
    lineNumbers: true,
    matchBrackets: true,
    theme: 'mbo',
    viewportMargin: Infinity
  };
  instance: any;

  getNode(): Observable<DialogNode> {
    return of(this.dlgService.node)
  }


  constructor(public settings: SettingsService,
    public dlgService: DialogService,
    public elem: ElementRef) {

    this.canEdit = true;
    }

    ngOnInit() {
      this.anyClickClose();


      this.getNode().subscribe(
        x => {
          console.log('node changed');
        },
        err => console.log('sub error'),
        () => console.log('complete')
      );

  }

  setTheme() {
  }

  codeTabsSelect(data: TabDirective) {
    let index: number = 0;
    if (this.dlgService.link) {

      if (this.dlgService.link.conditionScript) {
        index = 2;
      }
      if (this.dlgService.link.errorScript) {
        index = 1;
      }
      if (this.dlgService.link.script) {
        index = 0;
      }
    } else {

      if (this.dlgService.node) {

        if (this.dlgService.node.postScript) {
          index = 3;
        }
        if (this.dlgService.node.preScript) {
          index = 2;
        }
        if (this.dlgService.node.errorScript) {
          index = 1;
        }
        if (this.dlgService.node.script) {
          index = 0;
        }

      }
    }

    this.codeTabs.tabs[index].active = true;
  }

  changeOwner(event) {
    console.log(event);
    this.dlgService.loadLinkedGraphs();
    this.dlgService.setDirty(true);
  }

  changeLinkedGraph(event) {
    console.log(event);
    this.dlgService.loadLinkedNodes();
    this.dlgService.setDirty(true);
  }

  changeLinkedNode(event) {
    console.log(event);
    this.dlgService.setDirty(true);
  }


  tabRefresh(data: TabDirective) {

    console.log(data);
    if (data.elementRef && data.elementRef.nativeElement) {
      const children = data.elementRef.nativeElement.querySelector('.editor-container');
      if (children) {
        console.log(children);

        if (children.style.height !== "99%") {
          children.style.height = "99%";
        } else {
          children.style.height = "98%";
        }
      }
    }


    //if (this.editor) {
    //  this.editor.layout();
    //  window.resizeTo(window.innerWidth, window.innerHeight);

    //  console.log(data.elementRef);
    //  console.log(data.elementRef.nativeElement);

    //  let h = data.elementRef.nativeElement.offsetHeight;
    //  console.log(h);

    //}
    //const resizeEvent = new Event('resize');

    //window.dispatchEvent(resizeEvent);

  }

  onInit(editor) {
    this.editor = editor;
  };


    anyClickClose() {
        document.addEventListener('mousedown', this.checkCloseNodeSidebar, false);
    }

  checkCloseNodeSidebar = e => {
        const contains = (this.elem.nativeElement === e.target || this.elem.nativeElement.contains(e.target));
    if (!contains) {
      this.settings.setLayoutSetting('nodeSidebarOpen', false);
      this.dlgService.clear();
        }
    }

    ngOnDestroy() {
      document.removeEventListener('click', this.checkCloseNodeSidebar);
    }

  ngOnChanges(changes: SimpleChanges) {
    console.log('node changes');
  }

  setRoot() {
    console.log('setRoot');
    this.dlgService.graph.rootNodeID = this.dlgService.node.dialogNodeID;
  }

  deleteSelection() {
    console.log('deleteSelection');
    this.dlgService.deleteSelection();
  }


  onChange(event) {
    this.dlgService.setDirty(true);
  }
}
