import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from "ngx-spinner";

import { ChatstyleaiChatboxComponent } from './chatstyleai-chatbox.component';

import { MessageTimePipe } from './core/pipes/message-time.pipe';
import { CountdownPipe } from './core/pipes/countdown.pipe';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    ChatstyleaiChatboxComponent,
    MessageTimePipe,
    CountdownPipe
  ],
  imports: [
    HttpClientModule,
    NgxSpinnerModule,
    BsDatepickerModule.forRoot(),
    SharedModule.forRoot(),
  ],
  exports: [ChatstyleaiChatboxComponent],
  providers: [BsDatepickerConfig],
  bootstrap: []
})
export class ChatstyleaiChatboxModule { }
