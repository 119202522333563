import { Inject, Injectable, } from '@angular/core';
import { SettingsService } from '../../core/settings/settings.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../auth/auth/auth.service';
import { AppConfigService } from '../../shared/appconfig/appconfig.service'

import { Bot } from '../models/bot.model';
import { DialogGraph, DialogNode, DialogLink, NameValueItem } from '../models/dialogs.model';

const swal = require('sweetalert');

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  public bot: Bot;
  public graph: DialogGraph;
  public node: DialogNode;
  public link: DialogLink;
  public listener: IDialogServiceListener; 

  public owners: NameValueItem[];
  public linkGraphs: NameValueItem[];
  public linkNodes: NameValueItem[];

  public debugging: boolean;
  private headers: HttpHeaders;

  public working: boolean;
  public isDirty: boolean;

  getUrl(): string {
    return AppConfigService.settings.get('chatstyleapiServer');
  }

  constructor(
    public settings: SettingsService,
    private http: HttpClient,
    private authService: AuthService,
    @Inject('BASE_URL') baseUrl: string) {
    this.debugging = false;
    this.working = false;
  }

  hasSelection(): boolean {
    if (this.debugging) return false;
    if (this.node) return true;
    if (this.link) return true;
    return false;
  }

  clear() {
    console.log('clear');

    if (!this.working) {
      if (this.node || this.link) {
        this.settings.setLayoutSetting('nodeSidebarOpen', false);
        this.bot = null;
        this.node = null;
        this.link = null;
        if (this.listener) {
          this.listener.onClear();
        }
      }
   }

  }

  setDirty(val: boolean) {
    this.isDirty = val;
    console.log('setting dlgservice.dirty=' + this.isDirty);
  }

  setBot(b: Bot) {
    this.bot = b;
    this.loadOwners();
  }


  loadOwners() {
    this.owners = [];

    if (this.bot) {
      const b: NameValueItem = {
        display: 'Bot',
        value: this.bot.botID
      };
      this.owners.push(b);
    }

    const s: NameValueItem = {
      display: 'System',
      value: 'A0B0C0D0-E0F0-A0B0-A0B0-A0B0C0D0E0F0'
    };
    this.owners.push(s);
  }

  loadLinkedGraphs() {
    this.getGraphIds(this.node.ownerID).then((result) => {

      let filterGraph = this.graph.dialogGraphID;
      let validList = result.filter(function (obj) {
        return obj.value !== filterGraph;
      });

      this.linkGraphs = validList;

    });
  }

  loadLinkedNodes() {
    this.getNodeIds(this.node.ownerID, this.node.parentGraphID).then((result) => {

      this.linkNodes = result;
    });
  }

  selectGraph(g) {
    this.graph = g;
  }

  selectNode(n) {
    this.working = false;
    this.node = n;
    this.link = null;

    console.log(n);
    this.linkGraphs = [];
    this.linkNodes = [];

    if (this.node && this.node.type == 'link') {
      this.loadLinkedGraphs();
      this.loadLinkedNodes();
    }

    if (!this.debugging) {
      this.settings.setLayoutSetting('nodeSidebarOpen', true);
    }
  };

  selectLink(l) {
    this.working = false;
    this.link = l;
    this.node = null;

    console.log(l);

    if (!this.debugging) {

      this.settings.setLayoutSetting('nodeSidebarOpen', true);
    }
  };

  saveGraph(owner: string, graph: DialogGraph): Promise<DialogGraph> {
    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });
    return this.http
      .post<DialogGraph>(this.getUrl() + 'dialog/' + owner + "/" + graph.dialogGraphID,
        graph,
        { headers: this.headers }).toPromise();
  };

  addGraph(owner: string, graph: DialogGraph): Promise<DialogGraph> {
    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });
    return this.http
      .put<DialogGraph>(this.getUrl() + 'dialog/' + owner + "/" + graph.dialogGraphID,
        graph,
        { headers: this.headers }).toPromise();
  };

  deleteGraph(owner: string, graphId: string): Promise<DialogGraph> {
    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });
    return this.http
      .delete<DialogGraph>(this.getUrl() + 'dialog/' + owner + "/" + graphId,
        { headers: this.headers }).toPromise();
  };

  getGraphs(owner: string): Promise<DialogGraph[]> {

    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http
      .get<DialogGraph[]>(this.getUrl() + 'dialog/' + owner,
        { headers: this.headers }).toPromise();
  }

  getGraphIds(owner: string): Promise<NameValueItem[]> {

    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http
      .get<NameValueItem[]>(this.getUrl() + 'dialog/' + owner + '/graphids',
        { headers: this.headers }).toPromise();
  }

  getNodeIds(owner: string, graph: string): Promise<NameValueItem[]> {

    this.headers = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeaderValue() });

    return this.http
      .get<NameValueItem[]>(this.getUrl() + 'dialog/' + owner + '/' + graph + '/nodeids',
        { headers: this.headers }).toPromise();
  }

  emptyGraph(): DialogGraph {
    return {
      botID: '',
      dialogGraphID: '',
      version: '',
      label: '',
      description: '',
      rootNodeID: '',
      nodes: [],
      links: [],
      x: 0,
      y: 0
    };

  }

  emptyNode(): DialogNode {
    const node: DialogNode = {
      ownerID: '',
      dialogNodeID: '', //this.selectedGraph.dialogGraphID + "-" + this.selectedGraph.nodes.length,
      parentGraphID: '',
      type: '',
      label: '',
      text: '',
      resourceID: '',
      options: [],
      preScript: '',
      postScript: '',
      errorScript: '',
      noMatchResponse: '',
      script: '',
      delay: 0,
      active: true,
      links: [],
      x: 100,
      y: 100,
      maxExecutions: 0,
      blockInput: false,
      blockAttachments: false,
      overrideID: '',
      timeToLive: 0,
      data: ''
    };
    return node;
  }

  removeNodeFromGraph(graph: DialogGraph, nodeToRemove: DialogNode): DialogGraph {

    // remove all the links to the node to remove
    graph.nodes.forEach((n) => {
      if (n) {
        if (n.links && n.links.length > 0) {
          const link = n.links.find(l => l.targetNodeID === nodeToRemove.dialogNodeID && l.targetGraphID === graph.dialogGraphID);
          if (link) {
            const index = n.links.indexOf(link, 0);
            if (index > -1) {
              n.links.splice(index, 1);
            }
          }
        }
      }
    });
    const index = graph.nodes.indexOf(nodeToRemove, 0);
    if (index > -1) {
      graph.nodes.splice(index, 1);
    }

    return graph;
  };


  deleteSelection() {
    this.working = true;
    console.log('wtf');
    let msg = 'Are you sure you want to delete this ';
    if (this.node) {
      msg += ' node and all associated links?';
    } else {
      msg += ' link?';
    }

    swal({
      text: msg,
      icon: 'warning',
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes, delete it!',
          value: true,
          visible: true,
          className: "bg-danger",
          closeModal: true
        }
      }
    })
      .then((willDelete) => {
        if (willDelete) {

          this.setDirty(true);

          if (this.node) {
            this.removeNodeFromGraph(this.graph, this.node);

            //  const index = this.selectedGraph.nodes.indexOf(this.dlgService.node, 0);
            //  if (index > -1) {
            //    this.selectedGraph.nodes.splice(index, 1);
            //  }
          } else {

            if (this.link) {
              const id = this.link.dialogLinkID;

              //const connections = this.instance.getConnections({
              //  scope: [id]
              //}, true);
              //console.log(connections);

              for (var i = 0; i < this.graph.nodes.length; i++) {
                let n = this.graph.nodes[i];
                const index = n.links.indexOf(this.link, 0);
                if (index > -1) {
                  n.links.splice(index, 1);
                }
              }
              //this.graph.nodes.forEach((n) => {
              //  const index = n.links.indexOf(this.link, 0);
              //  if (index > -1) {
              //    n.links.splice(index, 1);
              //  }

              //});
            }

            //  const conn = connections[id][0];
            //  console.log(conn);
            //  const sourceNode = this.selectedGraph.nodes.find(n => (n.parentGraphID + '-' + n.dialogNodeID) === conn.sourceId);
            //  const index = sourceNode.links.indexOf(this.dlgService.link, 0);
            //  if (index > -1) {
            //    sourceNode.links.splice(index, 1);
            //  }
          }
          this.working = false;
          this.clear();
          if (this.listener) {
            this.listener.onUpdate();
          }
        }
      })
      .catch(err => {
      });
  };

}



export interface IDialogServiceListener {
  onClear();
  onUpdate();
};
