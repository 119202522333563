  <tabset [justified]="true" *ngIf="dlgService.hasSelection()">
    <tab>
      <ng-template tabHeading>
        <em class="icon-equalizer fa-lg"></em>
      </ng-template>
      <div class="p-2">
        <div *ngIf="canEdit" class="buttonarea">
          <button class="btn btn-primary mb-2" (click)="setRoot()">Set As Root</button>
          <button class="btn btn-danger mb-2" (click)="deleteSelection()">Delete</button>
        </div>
        <h4 class="text-muted text-thin">General</h4>
        <div class="clearfix">
          <p class="float-left">Label</p>
          <div class="float-right">
            <input *ngIf="dlgService.node" class="form-control form-control-rounded"
                   [(ngModel)]="dlgService.node.label" (change)="onChange($event)" />
            <input *ngIf="dlgService.link" class="form-control form-control-rounded"
                   [(ngModel)]="dlgService.link.label" (change)="onChange($event)" />
          </div>
        </div>
        <div class="clearfix">
          <p class="float-left">Override</p>
          <div class="float-right">
            <input *ngIf="dlgService.node" class="form-control form-control-rounded"
                   [(ngModel)]="dlgService.node.overrideID" (change)="onChange($event)" />
            <input *ngIf="dlgService.link" class="form-control form-control-rounded"
                   [(ngModel)]="dlgService.link.overrideID" (change)="onChange($event)" />
          </div>
        </div>
        <div class="clearfix">
          <h4>-</h4>
        </div>
        <div class="clearfix">
        </div>
        <div class="clearfix">
          <p class="float-left">Active</p>
          <div class="float-right">
            <label class="switch">
              <input *ngIf="dlgService.node" type="checkbox" [(ngModel)]="dlgService.node.active"
                     (change)="onChange($event)" />
              <input *ngIf="dlgService.link" type="checkbox" [(ngModel)]="dlgService.link.active"
                     (change)="onChange($event)" />
              <span></span>
            </label>
          </div>
        </div>
      </div>
      <div class="p-2" *ngIf="dlgService.node && dlgService.node.type === 'event'">
        <h4 class="text-muted text-thin">Dialog Event ID</h4>
        <div class="clearfix">
          <p class="float-left">Dialog Event ID</p>
          <div class="float-right">
            <input class="form-control form-control-rounded"
                   [(ngModel)]="dlgService.node.data" (change)="onChange($event)" />
          </div>
        </div>
      </div>
      <div class="p-2" *ngIf="dlgService.node && dlgService.node.type === 'link'">
        <h4 class="text-muted text-thin">Linked Node</h4>
        <div class="clearfix">
          <p class="float-left">Owner</p>
          <div class="float-right">
            <!--<input class="form-control form-control-rounded" [(ngModel)]="dlgService.node.ownerID" />-->
            <select class="custom-select" (change)="changeOwner($event)" [(ngModel)]="dlgService.node.ownerID">
              <option *ngFor="let o of dlgService.owners" [value]="o.value">{{o.display}}</option>
            </select>
          </div>
        </div>
        <div class="clearfix">
          <p class="float-left">Target Graph</p>
          <div class="float-right">
            <!--<input class="form-control form-control-rounded" [(ngModel)]="dlgService.node.parentGraphID" />-->
            <select class="custom-select" (change)="changeLinkedGraph($event)" [(ngModel)]="dlgService.node.parentGraphID">
              <option *ngFor="let g of dlgService.linkGraphs" [value]="g.value">{{g.display}}</option>
            </select>
          </div>
        </div>
        <div class="clearfix">
          <p class="float-left">Target Node</p>
          <div class="float-right">
            <!--<input class="form-control form-control-rounded" [(ngModel)]="dlgService.node.dialogNodeID" />-->
            <select class="custom-select" (change)="changeLinkedNode($event)" [(ngModel)]="dlgService.node.dialogNodeID">
              <option *ngFor="let n of dlgService.linkNodes" [value]="n.value">{{n.display}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="p-2" *ngIf="dlgService.node">
        <h4 class="text-muted text-thin">Node Output</h4>
        <div class="clearfix">
          <p class="float-left">Text</p>
          <div class="float-right" style="width: 100%">
            <textarea style="width: 100%" rows="5" class="form-control"
                      [(ngModel)]="dlgService.node.text" (change)="onChange($event)"></textarea>
          </div>
        </div>
        <div class="clearfix">
          <p class="float-left">Resource ID</p>
          <div class="float-right">
            <input *ngIf="dlgService.node" class="form-control form-control-rounded"
                   [(ngModel)]="dlgService.node.resourceID" (change)="onChange($event)" />
          </div>
        </div>
        <div class="clearfix">
          <p class="float-left">No Match Response</p>
          <div class="float-right" style="width: 100%">
            <textarea style="width: 100%" rows="5" class="form-control"
                      [(ngModel)]="dlgService.node.noMatchResponse" (change)="onChange($event)"></textarea>
          </div>
        </div>
        <div class="clearfix">
          <p class="float-left">Tries Before Failing</p>
          <div class="float-right" style="width: 100%">
            <input class="form-control form-control-rounded" type="number"
                   [(ngModel)]="dlgService.node.maxExecutions" (change)="onChange($event)" />
          </div>
        </div>
        <div class="clearfix">
          <p class="float-left">Time to Live (seconds)</p>
          <div class="float-right" style="width: 100%">
            <input class="form-control form-control-rounded" type="number"
                   [(ngModel)]="dlgService.node.timeToLive" (change)="onChange($event)" />
          </div>
        </div>
        <div class="clearfix">
          <p class="float-left">Options</p>
        </div>
        <div class="clearfix">
          <p class="float-left">
            <tag-input [(ngModel)]="dlgService.node.options"
                       blinkIfDupe="true" trimTags="true" theme="bootstrap"
                       (change)="onChange($event)"></tag-input>
        </div>
        <div class="clearfix" *ngIf="dlgService.node">
          <p class="float-left">Block Input?</p>
          <div class="float-right">
            <label class="switch">
              <input type="checkbox" [(ngModel)]="dlgService.node.blockInput" (change)="onChange($event)" />
              <span></span>
            </label>
          </div>
        </div>
        <div class="clearfix" *ngIf="dlgService.node">
          <p class="float-left">Block Attachments?</p>
          <div class="float-right">
            <label class="switch">
              <input type="checkbox" [(ngModel)]="dlgService.node.blockAttachments" (change)="onChange($event)" />
              <span></span>
            </label>
          </div>
        </div>
      </div>
      <div class="p-2" *ngIf="dlgService.link">
        <h4 class="text-muted text-thin">Link Options</h4>
        <div class="clearfix">
          <p class="float-left">Behavior</p>
          <div class="float-right">
            <select class="custom-select" [(ngModel)]="dlgService.link.behavior" (change)="onChange($event)">
              <option selected="">Never</option>
              <option value="Always">Always</option>
              <option value="Best">Best</option>
              <option value="Payload">Payload</option>
              <option value="NoMatch">No Match</option>
              <option value="Error">Error</option>
              <option value="BadLoop">Too Many Tries</option>
              <option value="Condition">Condition</option>
              <option value="Pattern">Pattern</option>
              <option value="NodeComplete">Node Complete</option>
              <option value="Expired">Expired</option>
            </select>
          </div>
        </div>
        <div class="clearfix">
        </div>
        <div class="clearfix">
          <p class="float-left">Conditional Operator</p>
          <div class="float-right">
            <select class="custom-select" [(ngModel)]="dlgService.link.conditionOperator" (change)="onChange($event)">
              <option value="None">None</option>
              <option value="And">And</option>
              <option value="Or">Or</option>
            </select>
          </div>
        </div>
        <div class="clearfix">
        </div>
        <div class="clearfix" *ngIf="dlgService.link">
          <p class="float-left">Match On</p>
        </div>
        <div class="clearfix" *ngIf="dlgService.link">
          <p class="float-left">
            <tag-input [(ngModel)]="dlgService.link.match" blinkIfDupe="true"
                       trimTags="true" theme="bootstrap" (change)="onChange($event)"></tag-input>
        </div>
      </div>
    </tab>
    <tab (selectTab)="codeTabsSelect($event)" *ngIf="dlgService.node || dlgService.link">
      <ng-template tabHeading>
        <em class="icon-options fa-lg"></em>
      </ng-template>
      <h3 class="text-center text-thin mt-4">Code</h3>
      <tabset #codeTabs>
        <tab *ngIf="dlgService.node" (selectTab)="tabRefresh($event)">
          <ng-template tabHeading>
            On Entering
          </ng-template>
          <div id="codePrescript" style="height: 800px" *ngIf="dlgService.node">
            <app-scripteditor [options]="{automaticLayout: true, theme: 'vs-dark'}" style="height: 100%"
                              [(ngModel)]="dlgService.node.preScript" (onInit)="onInit($event)"
                              (change)="onChange($event)"></app-scripteditor>
          </div>
        </tab>
        <tab (selectTab)="tabRefresh($event)">
          <ng-template tabHeading>
            On Message
          </ng-template>
          <div id="codeScriptNode" style="height: 800px" *ngIf="dlgService.node">
            <app-scripteditor [options]="{automaticLayout: true, theme: 'vs-dark'}"
                              style="height: 100%" [(ngModel)]="dlgService.node.script"
                              (change)="onChange($event)"></app-scripteditor>
          </div>
          <div id="codeScriptLink" style="height: 800px" *ngIf="dlgService.link">
            <app-scripteditor [options]="{automaticLayout: true, theme: 'vs-dark'}"
                              style="height: 100%" [(ngModel)]="dlgService.link.script"
                              (change)="onChange($event)"></app-scripteditor>
          </div>
        </tab>
        <tab *ngIf="dlgService.node" (selectTab)="tabRefresh($event)">
          <ng-template tabHeading>
            On Leaving
          </ng-template>
          <div id="codePostscript" style="height: 800px" *ngIf="dlgService.node">
            <app-scripteditor [options]="{automaticLayout: true, theme: 'vs-dark'}"
                              style="height: 100%" [(ngModel)]="dlgService.node.postScript"
                              (change)="onChange($event)"></app-scripteditor>
          </div>
        </tab>
        <tab *ngIf="dlgService.link" (selectTab)="tabRefresh($event)">
          <ng-template tabHeading>
            Condition
          </ng-template>
          <div id="codeCondition" style="height: 800px">
            <app-scripteditor [options]="{automaticLayout: true, theme: 'vs-dark'}"
                              style="height: 100%" [(ngModel)]="dlgService.link.conditionScript"
                              (change)="onChange($event)"></app-scripteditor>
          </div>
        </tab>
        <tab (selectTab)="tabRefresh($event)">
          <ng-template tabHeading>
            On Error
          </ng-template>
          <div id="codeErrorScriptNode" style="height: 800px" *ngIf="dlgService.node">
            <app-scripteditor [options]="{automaticLayout: true, theme: 'vs-dark'}"
                              style="height: 100%" [(ngModel)]="dlgService.node.errorScript"
                              (change)="onChange($event)"></app-scripteditor>
          </div>
          <div id="codeErrorScriptLink" style="height: 800px" *ngIf="dlgService.link">
            <app-scripteditor [options]="{automaticLayout: true, theme: 'vs-dark'}"
                              style="height: 100%" [(ngModel)]="dlgService.link.errorScript"
                              (change)="onChange($event)" ></app-scripteditor>
          </div>
        </tab>
      </tabset>
    </tab>
    <tab (selectTab)="tabRefresh($event)">
      <ng-template tabHeading>
        <em class="icon-question fa-lg"></em>
      </ng-template>
      <h3 class="text-center text-thin mt-4">Help</h3>
    </tab>
  </tabset>
