
const Dashboard = {
  text: 'Dashboard',
  link: '/dashboard/gettingstarted',
  icon: 'icon-speedometer',
  privilege: 'User',
  state: 'ready'
};

const BotGeneral = {
  text: 'General',
  link: '/bots/:id/general',
  icon: 'icon-info',
  state: 'ready',
  privilege: 'Admin'
};

const BotVisuals = {
  text: 'Visual',
  link: '/bots/:id/visuals',
  icon: 'icon-star',
  state: 'ready'
};

const BotAudience = {
  text: 'Audience',
  link: '/bots/:id/audience',
  icon: 'icon-microphone',
  state: 'ready'
};

const BotChannels = {
  text: 'Channels',
  link: '/bots/:id/channels',
  icon: 'icon-globe',
  state: 'ready'
};


const BotConversations = {
  text: 'Conversations',
  link: '/bots/:id/conversations',
  icon: 'icon-bubbles',
  state: 'ready'
};


const BotConfigurationSetup = {
  text: 'Setup',
  link: '/bots/:id/configuration',
  icon: 'icon-settings',
  state: 'setup'
};

const BotConfiguration = {
  text: 'Settings',
  link: '/bots/:id/configuration/:type',
  icon: 'icon-settings',
  state: 'ready'
};

const BotAdminConfiguration = {
  text: 'Admin',
  link: '/bots/:id/configuration/admin',
  icon: 'icon-briefcase',
  privilege: 'SuperAdmin'
};

const BotOrganization = {
  text: 'Organization',
  link: '/organization',
  icon: 'icon-organization',
  state: 'ready'
};

const BotOrgUsers = {
  text: 'Users',
  link: '/organization/users',
  icon: 'icon-user',
  state: 'ready'
};

const BotCommands = {
  text: 'Commands',
  link: '/bots/:id/commands',
  icon: 'icon-magic-wand',
  state: 'ready'
};

const BotForms = {
  text: 'Forms',
  link: '/bots/:id/forms',
  icon: 'icon-list',
  privilege: 'Forms',
  state: 'ready'
};

const BotReports = {
  text: 'Reports',
  link: '/bots/:id/reports',
  icon: 'icon-grid',
  state: 'ready',
  privilege: 'Reports'
};



const BotHelp = {
  text: 'Knowledge Base',
  elink: ':help_uri',
  target: 'blank',
  icon: 'icon-info'
};


const Connectors = {
  text: 'Connectors',
  link: '/connectors',
  icon: 'icon-link',
  privilege: 'User',
  state: 'ready'
};


const Onboarding = {
  text: 'Setup',
  link: '/setup',
  icon: 'icon-power',
  privilege: 'Onboarding',
  submenu: [
    {
      text: 'Profile',
      link: '/setup/profile'
    },
    //{
    //  text: 'Bot',
    //  link: '/setup/bot'
    //},
    //{
    //  text: 'Tickets',
    //  link: '/setup/tickets'
    //},
    //{
    //  text: 'Channels',
    //  link: '/setup/channels'
    //},
    //{
    //  text: 'Payment',
    //  link: '/setup/billing'
    //},
    //  {
    //    text: 'Activate',
    //    link: '/setup/activate'
    //  },
  ]
};

const BotDialogs = {
  text: 'Dialogs',
  link: '/bots/:id/dialogs',
  icon: 'icon-speech',
  privilege: 'Dialog'
};

const BotDialogEvents = {
  text: 'Dialog Events',
  link: '/bots/:id/dialogevents',
  icon: 'icon-pencil',
  privilege: 'Dialog'
};

const BotQueries = {
  text: 'Queries',
  link: '/bots/:id/queries',
  icon: 'icon-speech',
  privilege: 'Queries',
  state: 'ready'
};

const Account = {
  text: 'Account',
  link: '/account',
  icon: 'icon-credit-card',
  state: 'ready'
};


const BotWatchers = {
  text: 'Watchers',
  link: '/bots/:id/watchers',
  icon: 'icon-eye',
  privilege: 'Admin',
  state: 'ready'
};


const BotActions = {
  text: 'Actions',
  link: '/bots/:id/actions',
  icon: 'icon-target',
  privilege: 'Admin',
  state: 'ready'
};


const BotResources = {
  text: 'Resources',
  link: '/bots/:id/resources',
  icon: 'icon-notebook',
  privilege: 'Resources',
  state: 'ready'
};

const BotSecurity = {
  text: 'Security',
  link: '/bots/:id/roles',
  icon: 'icon-key',
  privilege: 'Admin'
};

const BotTags = {
  text: 'Tags',
  link: '/bots/:id/tags',
  icon: 'icon-tag',
  privilege: 'Admin'
};

export const customerMenu = [
  Dashboard,
  Onboarding,
  BotAdminConfiguration,
  BotGeneral,
  BotActions,
  BotChannels,
  BotCommands,
  //BotConversations,
  //BotAudience,
  Connectors,
  BotDialogs,
  BotDialogEvents,
  BotForms,
  BotOrganization,
  BotQueries,
  BotReports,
  BotResources,
  BotSecurity,
  BotConfigurationSetup,
  BotConfiguration,
  BotTags,
  BotOrgUsers,
  BotVisuals,
  //Account,
  BotHelp,
  BotWatchers
];
